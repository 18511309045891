import { InlineNotification, Modal, ModalBody, TextArea } from '@carbon/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FormCompletion.scss';

interface FormCompletionProps {
  onClose: () => void;
  comparisonStatus: {
    direct: { status: string; difference: number };
    indirect: { status: string; difference: number };
    activity: { status: string; difference: number };
    directIntensity?: { status: string; difference: number };
    indirectIntensity?: { status: string; difference: number };
  };
  onSubmitWithRemarks?: (remarks: string) => void;
}

const FormCompletion: React.FC<FormCompletionProps> = ({
  onClose,
  comparisonStatus,
  onSubmitWithRemarks = () => {},
}) => {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState('');

  // Check if there are any warnings for direct/indirect emissions or activity
  const hasEmissionsAboveThreshold =
    (comparisonStatus.direct.status === 'warning' && comparisonStatus.direct.difference > 20) ||
    (comparisonStatus.indirect.status === 'warning' && comparisonStatus.indirect.difference > 20);

  const hasBasicWarnings =
    comparisonStatus.direct.status === 'warning' ||
    comparisonStatus.indirect.status === 'warning' ||
    comparisonStatus.activity.status === 'warning';

  // Check intensity warnings separately
  const hasIntensityWarnings =
    comparisonStatus.directIntensity?.status === 'warning' ||
    comparisonStatus.indirectIntensity?.status === 'warning';

  // Form is valid if:
  // 1. No warnings at all, OR
  // 2. Only intensity warnings are present and all other values are in range, OR
  // 3. There are warnings (any type) and remarks are provided
  const MIN_REMARKS_LENGTH = 100;

  const isValid =
    (!hasBasicWarnings && !hasEmissionsAboveThreshold && !hasIntensityWarnings) || // No warnings at all
    (!hasBasicWarnings && !hasEmissionsAboveThreshold && hasIntensityWarnings) || // Only intensity warnings
    ((hasBasicWarnings || hasEmissionsAboveThreshold || hasIntensityWarnings) &&
      remarks.trim().length >= MIN_REMARKS_LENGTH); // Any warnings with remarks

  const handleClose = () => {
    onClose();
    window.location.href = '/';
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      modalLabel="CORA"
      modalHeading={
        hasEmissionsAboveThreshold
          ? t('survey.submission.validation.error')
          : t('survey.submission.success.title')
      }
      primaryButtonText={
        hasEmissionsAboveThreshold || hasBasicWarnings || hasIntensityWarnings
          ? 'Submit with Remarks' // Text for cases with any warnings
          : 'Submit' // Text for normal case
      }
      secondaryButtonText="Back to Survey"
      primaryButtonDisabled={!isValid}
      onRequestSubmit={() => {
        if (isValid) {
          onSubmitWithRemarks(remarks);
        }
      }}
      onSecondarySubmit={() => onClose()}
    >
      <ModalBody>
        <div className="flex flex-col items-center justify-center p-4">
          {hasEmissionsAboveThreshold || hasBasicWarnings || hasIntensityWarnings ? (
            <>
              <InlineNotification
                kind="error"
                hideCloseButton
                title={t('survey.submission.validation.errorTitle')}
                subtitle={t('survey.submission.validation.errorSubtitle')}
              />
              <div className="remarks-section mt-4">
                <TextArea
                  labelText={t('survey.submission.modalData.remarkText')}
                  placeholder={t('survey.submission.modalData.remarkPlaceholder')}
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setRemarks(e.target.value)
                  }
                  invalid={remarks.trim().length < MIN_REMARKS_LENGTH}
                  invalidText={`${t('survey.submission.modalData.remarkInvalid')} (${
                    remarks.trim().length
                  }/${MIN_REMARKS_LENGTH} characters minimum)`}
                />
              </div>
            </>
          ) : (
            <p className="text-center text-gray-600">
              {t('survey.submission.success.description')}
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormCompletion;
