import { useOidc } from '@axa-fr/react-oidc';
import { Column, Loading, ProgressIndicator, ProgressStep, ToastNotification } from '@carbon/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmissionSource, SurveySupplierInput } from '../../types/SurveySupplierInput';
import FormCompletion from './FormCompletion/FormCompletion';
import './FormMultisteps.scss';
import FormStep1 from './FormStep1/FormStep1';
import FormStep2 from './FormStep2/FormStep2';
import FormStep3 from './FormStep3/FormStep3';
import FormStep4 from './FormStep4/FormStep4';
import FormStep4_5 from './FormStep4_5/FormStep4_5';
import FormStep5 from './FormStep5/FormStep5';
import FormStep6 from './FormStep6/FormStep6';
import FormStep7 from './FormStep7/FormStep7';
import FormStep8 from './FormStep8/FormStep8';
import FormStep9, { UploadFile } from './FormStep9/FormStep9';

export interface FormStateAttribute {
  name: string;
  value?: any;
}

export interface NextStepProps {
  isIntermediateStep?: boolean;
}

// Helper function to compare states
function statesAreEqual(prevState: any, currentState: any) {
  return JSON.stringify(prevState) === JSON.stringify(currentState);
}

const FormMultisteps = ({
  data,
  currentStep,
  setCurrentStep,
  setReload,
}: {
  data: SurveySupplierInput;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setReload: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const newBackendUrl = process.env.REACT_APP_NEW_BACKEND_URL;
  const [formState, setFormState] = useState<SurveySupplierInput>(data);
  const [isVertical, setIsVertical] = useState(window.innerWidth < 600);
  const { isAuthenticated } = useOidc();
  const [purchasedEmissions, setPurchasedEmissions] = useState<EmissionSource[]>([]); // Add purchased emissions state
  const [showToast, setShowToast] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCompletion, setShowCompletion] = useState(false);
  const [submittedSurveyId, setSubmittedSurveyId] = useState<string>('');
  const [comparisonStatus, setComparisonStatus] = useState<any>(null);
  const [validationData, setValidationData] = useState(null);
  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    function handleResize() {
      setIsVertical(window.innerWidth < 600); // Update based on screen width
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Check if the formState has been initialized
    if (!formState.id) {
      // Initialize the formState with the data prop
      setFormState(data);
    }
  }, [data, formState.id]); // Add formState.id as a dependency to check its initialization state

  const prevFormStateRef = useRef<SurveySupplierInput>();

  const fetchDraftData = async (surveyId: string) => {
    try {
      if (surveyId) {
        if (isAuthenticated) {
          const result = await fetch(`${backendUrl}/api/surveys/${surveyId}/draft`, {
            method: 'GET',
          });
          return result.json();
        } else {
          const data = localStorage.getItem(`cora_survey_${surveyId}`);
          return JSON.parse(data || '{}');
        }
      }
    } catch (e) {
      console.error('error', e);
    }
  };

  const saveDraftData = async (surveyId: string) => {
    try {
      if (surveyId) {
        const draftData = JSON.stringify({
          step_counter: currentStep,
          survey_data: JSON.stringify(formState),
        });
        if (isAuthenticated) {
          await fetch(`${backendUrl}/api/surveys/${surveyId}/draft`, {
            method: 'POST',
            body: draftData,
            headers: { 'Content-Type': 'application/json' },
          });
        } else {
          localStorage.setItem(`cora_survey_${surveyId}`, draftData);
        }
      }
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data?.id) {
        setLoading(true);
        const draftData = await fetchDraftData(data?.id);

        if (draftData && draftData.step_counter) {
          setCurrentStep(draftData.step_counter);
        }

        const initialState = draftData?.survey_data ? JSON.parse(draftData.survey_data) : data;
        if (currentStep !== 9) {
          setFormState(initialState);
        }
        prevFormStateRef.current = initialState;
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  useEffect(() => {
    const saveData = async () => {
      if (prevFormStateRef.current && !statesAreEqual(prevFormStateRef.current, formState)) {
        await saveDraftData(data?.id);

        prevFormStateRef.current = formState; // Consider moving this line outside the condition
      }
    };

    saveData();
  }, [currentStep, backendUrl, formState.purchasedEmissions]);

  const handleChange = (event: { target: { name: any; value: any }; name?: any; value?: any }) => {
    if (event.target) {
      setFormState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value || null,
      }));
    } else if (event.name) {
      setFormState((prevState) => ({
        ...prevState,
        [event.name]: event.value,
      }));
    }
  };

  const handleDiscard = () => {
    navigate(`/?surveyId=${formState.id}`);
  };

  const handleSubmit = async (event: { preventDefault: () => void }, validationStatus?: any) => {
    event.preventDefault();

    setComparisonStatus(validationStatus);
    // setSubmittedSurveyId(formState.id);
    setShowCompletion(true);
  };

  const handleFinalSubmit = async (remarks: string | undefined) => {
    if (formState.status !== 'dummy_status') {
      try {
        if (files && files.length > 0) {
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            if (files[i].file instanceof File) {
              if (files[i].category === 'additional') {
                formData.append('additional', files[i].file);
              }

              if (files[i].category === 'evidence') {
                formData.append('evidence', files[i].file);
              }

              if (files[i].category === 'methodology') {
                formData.append('methodology', files[i].file);
              }
            }
          }

          await fetch(`${newBackendUrl}/api/v1/surveys/${data.id}/files`, {
            method: 'PUT',
            body: formData,
          });
        }

        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');

        const formStateWithRemarks = {
          ...formState,
          ...(remarks?.trim() ? { remarks } : {}),
        };

        const requestBody = {
          survey_data: JSON.stringify(formStateWithRemarks),
        };

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(requestBody),
        };
        await fetch(`${backendUrl}/api/surveys/${data.id}/submit`, requestOptions);
        navigate(`/?surveyId=${formState.id}`);

        setReload(true);
      } catch (error) {
        console.error('Error submitting survey:', error);
      }
    } else {
      navigate(`/?testCN=${formState.CNNumber}`);
    }
  };
  const nextStep = (props?: NextStepProps) => {
    if (isAuthenticated) {
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
      }, 3_000);
    }

    if (currentStep === 6) {
      setCurrentStep(currentStep + 2);
    } else {
      props?.isIntermediateStep
        ? setCurrentStep(currentStep + 0.5)
        : setCurrentStep(Math.floor(currentStep + 1));
    }
  };

  const prevStep = () => {
    if (currentStep === 8) {
      setCurrentStep(currentStep - 2);
    } else {
      setCurrentStep(Math.floor(currentStep - 1));
    }
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormStep1
            formState={formState}
            handleDiscard={handleDiscard}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <FormStep2
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <FormStep3
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <FormStep4
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 4.5:
        return (
          <FormStep4_5
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 5:
        return (
          <FormStep5
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 6:
        return (
          <FormStep6
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 7:
        return (
          <FormStep7
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 8:
        return (
          <FormStep8
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 9:
        return (
          <FormStep9
            formState={formState}
            handleChange={handleChange}
            prevStep={prevStep}
            onChangeFiles={(newFiles: UploadFile[]) => setFiles(newFiles)}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  if (loading) return <Loading withOverlay={true} />;

  return (
    <>
      {currentStep !== 9 ? (
        <Column lg={16} md={8} sm={4} className="survey-section">
          <h1>
            {t('survey.header', {
              CNNumber: formState.CNNumber,
              AGC: formState.AGC,
            })}
          </h1>
        </Column>
      ) : null}

      <div className="container">
        {currentStep !== 9 && (
          <ProgressIndicator
            className="cora-progress-indicator"
            spaceEqually={true}
            vertical={isVertical}
          >
            <ProgressStep
              current={currentStep === 1}
              complete={currentStep > 1}
              label={t('survey.step1.name')}
              description={t('survey.step1.description')}
            />
            <ProgressStep
              current={currentStep === 2}
              complete={currentStep > 2}
              label={t('survey.step2.name')}
              description={t('survey.step2.description')}
            />
            <ProgressStep
              current={currentStep === 3}
              complete={currentStep > 3}
              label={t('survey.step3.name')}
              description={t('survey.step3.description')}
            />
            <ProgressStep
              current={currentStep === 4 || currentStep === 4.5}
              complete={currentStep > 4}
              label={t('survey.step4.name')}
              description={t('survey.step4.description')}
            />
            <ProgressStep
              current={currentStep === 5}
              complete={currentStep > 5}
              label={t('survey.step5.name')}
              description={t('survey.step5.description')}
            />
            <ProgressStep
              current={currentStep === 6}
              complete={currentStep > 6}
              label={t('survey.step6.name')}
              description={t('survey.step6.description')}
            />
            {/*<ProgressStep
              current={currentStep === 7}
              complete={currentStep > 7}
              label={t('survey.step7.name')}
              description={t('survey.step7.description')}
            />*/}
            <ProgressStep
              current={currentStep === 8}
              complete={currentStep > 8}
              label={t('survey.step8.name')}
              description={t('survey.step8.description')}
            />
          </ProgressIndicator>
        )}
        {currentStep !== 9 ? (
          <div className={`cora-form-container`}>{renderFormStep()}</div>
        ) : (
          <div className={'cora-form-container-step9'}>{renderFormStep()}</div>
        )}

        {showCompletion && (
          <FormCompletion
            onClose={() => {
              setShowCompletion(false);
            }}
            comparisonStatus={comparisonStatus}
            onSubmitWithRemarks={(remarks) => {
              handleFinalSubmit(remarks); // Call actual submission here
            }}
          />
        )}

        {showToast && (
          <ToastNotification
            role="status"
            kind="success"
            timeout={3_000}
            title={t('common.notification.savedSurveyStep')}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 100000,
            }}
          />
        )}
      </div>
    </>
  );
};

export default FormMultisteps;
