import {
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  FileUploader,
  Form,
  Grid,
  InlineNotification,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
} from '@carbon/react';
import { CheckmarkFilled, Download, TrashCan, WarningFilled } from '@carbon/react/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './FormStep9.scss';

interface IEmission {
  category: string;
  emission_name: string;
  activity: number | string;
  unit: 't' | 'kg';
  emission_id: number;
  direct_multiplier: number;
  indirect_multiplier: number;
}

export interface UploadFile {
  id: number;
  category: string;
  uploadDate: string;
  file: File;
}

const FormStep9 = ({ formState, prevStep, handleSubmit: submitForm, onChangeFiles }: any) => {
  const { t } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [defaultEmissions, setDefaultEmissions] = useState<any>(null);
  const [emissionIntensity, setEmissionIntensity] = useState<any>(null);
  const [comparisonStatus, setComparisonStatus] = useState({
    direct: { status: 'loading', difference: 0 },
    indirect: { status: 'loading', difference: 0 },
    activity: { status: 'loading', difference: 0 },
    directIntensity: { status: 'loading', difference: 0 },
    indirectIntensity: { status: 'loading', difference: 0 },
  });

  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);

  // File handling functions
  const handleAddFile = (category: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) return;
    const fileArray = Array.from(files);

    if (fileArray) {
      const newFiles = fileArray.map((file, index) => ({
        id: uploadFiles.length + index + 1,
        category,
        uploadDate: new Date().toISOString(),
        file, // Hier wird das `File`-Objekt direkt zugewiesen
      }));

      setUploadFiles([...uploadFiles, ...newFiles]);
      onChangeFiles([...uploadFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (fileId: number) => {
    const newFiles = uploadFiles.filter((file) => file.id !== fileId);
    setUploadFiles(newFiles);
  };

  const PowerConsumptionComparison = ({ indirectEmission }: { indirectEmission: any }) => {
    const powerLevel = Number(indirectEmission) || 0;

    const getPowerCategory = (power: number) => {
      if (power >= 100) return 0;
      if (power >= 10) return 1;
      if (power >= 1) return 2;
      return 3;
    };

    const powerCategory = getPowerCategory(powerLevel);

    return (
      <div className="details-section mt-4">
        <div className="comparison-header">
          <h4>Power Consumption Context</h4>
        </div>
        <div className="details-content">
          <div className="power-comparison-item highlight">
            <div className="power-level">
              <span className="label">Your Installation's Power Consumption</span>
              <span className="value">
                {typeof powerLevel === 'number' ? powerLevel.toFixed(1) : '0.0'} MW
              </span>
            </div>
          </div>

          {powerComparisons.map((comparison, index) => (
            <div
              key={comparison.power}
              className={`power-comparison-item ${index === powerCategory ? 'current' : ''}`}
            >
              <div className="power-level">
                <span className="label">Reference Level</span>
                <span className="value">{comparison.power}</span>
              </div>
              <div className="examples">
                <span className="label">Comparable to</span>
                <ul className="example-list">
                  {comparison.examples.map((example, i) => (
                    <li key={i} className="value">
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const powerComparisons = [
    {
      power: '100 MW+',
      examples: [
        t('survey.submission.power.example100_1'),
        t('survey.submission.power.example100_2'),
        t('survey.submission.power.example100_3'),
      ],
    },
    {
      power: '10 MW',
      examples: [
        t('survey.submission.power.example10_1'),
        t('survey.submission.power.example10_2'),
        t('survey.submission.power.example10_3'),
      ],
    },
    {
      power: '1 MW',
      examples: [
        t('survey.submission.power.example1_1'),
        t('survey.submission.power.example1_2'),
        t('survey.submission.power.example1_3'),
      ],
    },
    {
      power: '0.1 MW (100 kW)',
      examples: [
        t('survey.submission.power.example0_1'),
        t('survey.submission.power.example0_2'),
        t('survey.submission.power.example0_3'),
      ],
    },
  ];

  const convertToTonnes = (value: number, unit: 't' | 'kg'): number => {
    return unit === 'kg' ? value / 1000 : value;
  };

  const calculateTotalEmissions = (emissions: IEmission[]): number => {
    return emissions.reduce((sum: number, emission: IEmission) => {
      const activityValue = Number(emission.activity);
      if (isNaN(activityValue)) {
        console.warn(`Invalid activity value: ${emission.activity}`);
        return sum;
      }
      return sum + convertToTonnes(activityValue, emission.unit);
    }, 0);
  };

  useEffect(() => {
    const fetchAndCompareEmissions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/emissions/default/${formState.CNNumber}`);
        const defaultData = await response.json();
        setDefaultEmissions(defaultData);

        // Fetch emission intensity if country code is available
        let intensityData = null;
        if (formState.country?.country_code) {
          try {
            const cnNumberString = String(formState.CNNumber);
            console.log('Making intensity API call with:', {
              cnNumber: cnNumberString,
              countryCode: formState.country.country_code,
              url: `${backendUrl}/api/emissions/intensity/${cnNumberString}/${formState.country.country_code}`,
            });

            const intensityResponse = await fetch(
              `${backendUrl}/api/emissions/intensity/${cnNumberString}/${formState.country.country_code}`,
            );

            if (!intensityResponse.ok) {
              console.error('Intensity API response not ok:', {
                status: intensityResponse.status,
                statusText: intensityResponse.statusText,
              });
              const errorText = await intensityResponse.text();
              console.error('Error response:', errorText);
              return;
            }

            intensityData = await intensityResponse.json();
            console.log('Received intensity data:', intensityData);

            if (intensityData) {
              setEmissionIntensity(intensityData);
            } else {
              console.log('No intensity data received');
            }
          } catch (error) {
            console.error('Error fetching intensity data:', error);
          }
        } else {
          console.log('Country code not available:', formState.country);
        }
        if (defaultData) {
          const totalActivity = calculateTotalEmissions(formState.directEmissions);

          const directDiff =
            ((formState.calculatedEmissionsDirectPerTonne - defaultData.direct) /
              defaultData.direct) *
            100;
          const indirectDiff =
            ((formState.calculatedEmissionsIndirectPerTonne - defaultData.indirect) /
              defaultData.indirect) *
            100;
          const activityDiff =
            ((totalActivity - formState.productionCalc) / formState.productionCalc) * 100;

          let directIntensityDiff = 0;
          let indirectIntensityDiff = 0;

          if (intensityData) {
            directIntensityDiff =
              ((formState.calculatedEmissionsDirectPerTonne - intensityData.direct_emissions) /
                intensityData.direct_emissions) *
              100;
            indirectIntensityDiff =
              ((formState.calculatedEmissionsIndirectPerTonne - intensityData.indirect_emissions) /
                intensityData.indirect_emissions) *
              100;
          }

          setComparisonStatus({
            direct: {
              status: Math.abs(directDiff) > 20 ? 'warning' : 'success',
              difference: directDiff,
            },
            indirect: {
              status: Math.abs(indirectDiff) > 20 ? 'warning' : 'success',
              difference: indirectDiff,
            },
            activity: {
              status: activityDiff < -20 || activityDiff > 50 ? 'warning' : 'success',
              difference: activityDiff,
            },
            directIntensity: {
              status: Math.abs(directIntensityDiff) > 20 ? 'warning' : 'success',
              difference: directIntensityDiff,
            },
            indirectIntensity: {
              status: Math.abs(indirectIntensityDiff) > 20 ? 'warning' : 'success',
              difference: indirectIntensityDiff,
            },
          });
        }
      } catch (err) {
        console.error('Error fetching emissions data:', err);
      }
    };

    if (formState.CNNumber) {
      fetchAndCompareEmissions();
    }
  }, [
    formState.CNNumber,
    formState.calculatedEmissionsDirectPerTonne,
    formState.calculatedEmissionsIndirectPerTonne,
    formState.country?.country_code,
  ]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm(event, comparisonStatus);
  };

  const categories: Record<string, { name: string; items: IEmission[] }> = {
    CBAM_PRFG: { name: 'Fuels & Gases', items: [] },
    CBAM_PRRP: { name: 'Precursors', items: [] },
    CBAM_PROI: { name: 'Other Inputs', items: [] },
  };

  formState.directEmissions.forEach((emission: IEmission) => {
    if (categories[emission.category]) {
      categories[emission.category].items.push(emission);
    }
  });

  const sourceStreamContent = Object.entries(categories)
    .map(([key, category]) => {
      if (category.items.length > 0) {
        return (
          <AccordionItem key={key} title={`${category.name} (${category.items.length})`}>
            {category.items.map((item) => (
              <p key={item.emission_id} className="text-gray">
                {`${item.emission_name} (${item.activity}${item.unit})`}
              </p>
            ))}
          </AccordionItem>
        );
      }
      return null;
    })
    .filter(Boolean);

  const ValidationHints = ({ comparisonStatus }: { comparisonStatus: any }) => {
    // Only show if there are any warnings
    if (
      comparisonStatus.direct.status !== 'warning' &&
      comparisonStatus.indirect.status !== 'warning' &&
      comparisonStatus.activity.status !== 'warning' &&
      comparisonStatus.directIntensity?.status !== 'warning' &&
      comparisonStatus.indirectIntensity?.status !== 'warning'
    ) {
      return null;
    }
    return (
      <div className="validation-hints mt-4">
        {/* Emissions too high */}
        {(comparisonStatus.direct.status === 'warning' &&
          comparisonStatus.direct.difference > 20) ||
        (comparisonStatus.indirect.status === 'warning' &&
          comparisonStatus.indirect.difference > 20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.highEmissionTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.highEmission1')}</li>
              <li>{t('survey.submission.validation.highEmission2')}</li>
              <li>{t('survey.submission.validation.highEmission3')}</li>
            </ul>
          </div>
        ) : null}

        {/* Emissions too low */}
        {(comparisonStatus.direct.status === 'warning' &&
          comparisonStatus.direct.difference < -20) ||
        (comparisonStatus.indirect.status === 'warning' &&
          comparisonStatus.indirect.difference < -20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.lowEmissionTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
            </ul>
          </div>
        ) : null}

        {/* Intensity emissions too high */}
        {(comparisonStatus.directIntensity?.status === 'warning' &&
          comparisonStatus.directIntensity.difference > 20) ||
        (comparisonStatus.indirectIntensity?.status === 'warning' &&
          comparisonStatus.indirectIntensity.difference > 20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.intensityHighTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.intensityHigh1')}</li>
              <li>{t('survey.submission.validation.intensityHigh2')}</li>
              <li>{t('survey.submission.validation.intensityHigh3')}</li>
            </ul>
          </div>
        ) : null}

        {/* Intensity emissions too low */}
        {(comparisonStatus.directIntensity?.status === 'warning' &&
          comparisonStatus.directIntensity.difference < -20) ||
        (comparisonStatus.indirectIntensity?.status === 'warning' &&
          comparisonStatus.indirectIntensity.difference < -20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.intensityLowTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.intensityLow1')}</li>
              <li>{t('survey.submission.validation.intensityLow2')}</li>
              <li>{t('survey.submission.validation.intensityLow3')}</li>
            </ul>
          </div>
        ) : null}

        {/* Activity vs Production deviation */}
        {comparisonStatus.activity.status === 'warning' && (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.activityLevelTitle')}</h5>
            {comparisonStatus.activity.difference > 50 ? (
              <div className="hint-content">
                <p className="hint-emphasis">
                  {t('survey.submission.validation.activityLevelSubtitle')}
                </p>
                <ul>
                  <li>{t('survey.submission.validation.activityLevel1')}</li>
                  <li>{t('survey.submission.validation.activityLevel2')}</li>
                  <li>{t('survey.submission.validation.activityLevel3')}</li>
                </ul>
              </div>
            ) : comparisonStatus.activity.difference < -20 ? (
              <div className="hint-content">
                <p className="hint-emphasis">
                  {t('survey.submission.validation.activityLevelSubtitle2')}
                </p>
                <ul>
                  <li>{t('survey.submission.validation.activityLevel4')}</li>
                  <li>{t('survey.submission.validation.activityLevel5')}</li>
                  <li>{t('survey.submission.validation.activityLevel6')}</li>
                </ul>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const EmissionsComparison = () => {
    // Add null checks and default values for calculations
    const calculatedEmissionsDirectPerTonne = formState?.calculatedEmissionsDirectPerTonne ?? 0;
    const calculatedEmissionsIndirectPerTonne = formState?.calculatedEmissionsIndirectPerTonne ?? 0;
    const productionCalc = formState?.productionCalc ?? 0;

    const totalActivity = calculateTotalEmissions(formState.directEmissions);

    return (
      <div className="emissions-comparison">
        <Grid>
          <Column sm={4} md={8} lg={16}>
            {/* User's Emissions Values */}
            <div className="comparison-section user-values">
              <div className="comparison-header">
                <h4>{t('survey.submission.validation.userEmissionValues')}</h4>
              </div>
              <div className="comparison-values">
                <div className="value-item">
                  <span className="label">
                    {t('survey.submission.validation.directEmissionLabel')}
                  </span>
                  <span className="value">
                    {calculatedEmissionsDirectPerTonne.toFixed(2)} t CO2e/t
                  </span>
                </div>
                <div className="value-item">
                  <span className="label">
                    {t('survey.submission.validation.indirectEmissionLabel')}
                  </span>
                  <span className="value">
                    {calculatedEmissionsIndirectPerTonne.toFixed(2)} t CO2e/t
                  </span>
                </div>
              </div>
            </div>

            {/* Default Emissions Comparison */}
            <div className="comparison-section">
              <div className="comparison-header">
                <h4>{t('survey.submission.validation.defaultEmissionsLabel')}</h4>
                <div className="tag-container">
                  <Tag
                    type={comparisonStatus?.direct?.status === 'warning' ? 'red' : 'green'}
                    renderIcon={
                      comparisonStatus?.direct?.status === 'warning'
                        ? WarningFilled
                        : CheckmarkFilled
                    }
                  >
                    {t('survey.submission.validation.directEmissionLabel')}
                  </Tag>
                  <Tag
                    type={comparisonStatus?.indirect?.status === 'warning' ? 'red' : 'green'}
                    renderIcon={
                      comparisonStatus?.indirect?.status === 'warning'
                        ? WarningFilled
                        : CheckmarkFilled
                    }
                  >
                    {t('survey.submission.validation.indirectEmissionLabel')}
                  </Tag>
                </div>
              </div>
              <div className="comparison-values">
                <div className="emissions-row">
                  <div className="emission-type-label">
                    {t('survey.submission.validation.directEmissionLabel')}
                  </div>
                  <div className="emissions-data">
                    <div className="primary-values">
                      <div className="value-item">
                        <span className="value">
                          {defaultEmissions?.direct?.toFixed(2) ?? '0.00'} t CO2e/t
                        </span>
                      </div>
                    </div>
                    <div className="delta-values">
                      <div className="value-item">
                        <span className="label">
                          {t('survey.submission.validation.emissionDifference')}
                        </span>
                        <span
                          className={`value ${
                            comparisonStatus?.direct?.status === 'warning' ? 'warning' : 'success'
                          }`}
                        >
                          {comparisonStatus?.direct?.difference?.toFixed(1) ?? '0.0'}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="emissions-row">
                  <div className="emission-type-label">
                    {t('survey.submission.validation.indirectEmissionLabel')}
                  </div>
                  <div className="emissions-data">
                    <div className="primary-values">
                      <div className="value-item">
                        <span className="value">
                          {defaultEmissions?.indirect?.toFixed(2) ?? '0.00'} t CO2e/t
                        </span>
                      </div>
                    </div>
                    <div className="delta-values">
                      <div className="value-item">
                        <span className="label">
                          {t('survey.submission.validation.emissionDifference')}
                        </span>
                        <span
                          className={`value ${
                            comparisonStatus?.indirect?.status === 'warning' ? 'warning' : 'success'
                          }`}
                        >
                          {comparisonStatus?.indirect?.difference?.toFixed(1) ?? '0.0'}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {emissionIntensity && (
              <div className="comparison-section">
                <div className="comparison-header">
                  <h4>{t('survey.submission.validation.countryIntensityLabel')}</h4>
                  <div className="tag-container">
                    <Tag
                      type={
                        comparisonStatus?.directIntensity?.status === 'warning' ? 'red' : 'green'
                      }
                      renderIcon={
                        comparisonStatus?.directIntensity?.status === 'warning'
                          ? WarningFilled
                          : CheckmarkFilled
                      }
                    >
                      {t('survey.submission.validation.directEmissionLabel')}
                    </Tag>
                    <Tag
                      type={
                        comparisonStatus?.indirectIntensity?.status === 'warning' ? 'red' : 'green'
                      }
                      renderIcon={
                        comparisonStatus?.indirectIntensity?.status === 'warning'
                          ? WarningFilled
                          : CheckmarkFilled
                      }
                    >
                      {t('survey.submission.validation.indirectEmissionLabel')}
                    </Tag>
                  </div>
                </div>
                <div className="comparison-values">
                  <div className="emissions-row">
                    <div className="emission-type-label">
                      {t('survey.submission.validation.directEmissionLabel')}
                    </div>
                    <div className="emissions-data">
                      <div className="primary-values">
                        <div className="value-item">
                          <span className="value">
                            {emissionIntensity?.direct_emissions?.toFixed(2) ?? '0.00'} t CO2e/t
                          </span>
                        </div>
                      </div>
                      <div className="delta-values">
                        <div className="value-item">
                          <span className="label">
                            {t('survey.submission.validation.emissionDifference')}
                          </span>
                          <span
                            className={`value ${
                              comparisonStatus?.directIntensity?.status === 'warning'
                                ? 'warning'
                                : 'success'
                            }`}
                          >
                            {comparisonStatus?.directIntensity?.difference?.toFixed(1) ?? '0.0'}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="emissions-row">
                    <div className="emission-type-label">
                      {t('survey.submission.validation.indirectEmissionLabel')}
                    </div>
                    <div className="emissions-data">
                      <div className="primary-values">
                        <div className="value-item">
                          <span className="value">
                            {emissionIntensity?.indirect_emissions?.toFixed(2) ?? '0.00'} t CO2e/t
                          </span>
                        </div>
                      </div>
                      <div className="delta-values">
                        <div className="value-item">
                          <span className="label">
                            {t('survey.submission.validation.emissionDifference')}
                          </span>
                          <span
                            className={`value ${
                              comparisonStatus?.indirectIntensity?.status === 'warning'
                                ? 'warning'
                                : 'success'
                            }`}
                          >
                            {comparisonStatus?.indirectIntensity?.difference?.toFixed(1) ?? '0.0'}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Activity vs Production */}
            <div className="comparison-section">
              <div className="comparison-header">
                <h4>{t('survey.submission.validation.activityLevelTitle')}</h4>
                {comparisonStatus?.activity?.status === 'warning' ? (
                  <Tag type="red" renderIcon={WarningFilled}>
                    {t('survey.submission.validation.deviationHint')}
                  </Tag>
                ) : (
                  <Tag type="green" renderIcon={CheckmarkFilled}>
                    {t('survey.submission.validation.deviationHint2')}
                  </Tag>
                )}
              </div>
              <div className="comparison-values">
                <div className="value-item">
                  <span className="label">
                    {t('survey.submission.validation.totalActivityLabel')}
                  </span>
                  <span className="value">{totalActivity.toFixed(2)} t</span>
                </div>
                <div className="value-item">
                  <span className="label">
                    {t('survey.submission.validation.productionTotalLabel')}
                  </span>
                  <span className="value">{productionCalc} t</span>
                </div>
                <div className="value-item">
                  <span className="label">
                    {t('survey.submission.validation.emissionDifference')}
                  </span>
                  <span
                    className={`value ${
                      comparisonStatus?.activity?.status === 'warning' ? 'warning' : 'success'
                    }`}
                  >
                    {comparisonStatus?.activity?.difference?.toFixed(1) ?? '0.0'}%
                  </span>
                </div>
              </div>
            </div>
            <h4>{t('survey.submission.validation.checksLabel')}</h4>
            <ValidationHints comparisonStatus={comparisonStatus} />
          </Column>
        </Grid>
      </div>
    );
  };

  function mapCategoryName(key: string): string {
    switch (key) {
      case 'additional':
        return 'Additional Documents';
      case 'evidence':
        return 'Emissions Evidence';
      case 'methodology':
        return 'Monitoring Methodology';
      default:
        return '';
    }
  }

  return (
    <Form onSubmit={handleFormSubmit} id="step9" className="form-step">
      <h3 className="form-step-title">{t('survey.step9.name')}</h3>

      <InlineNotification
        kind="info"
        hideCloseButton
        title={t('survey.step9.infoTitle')}
        subtitle={t('survey.step9.infoSubtitle')}
      />

      <Tabs>
        <TabList aria-label="Survey Summary">
          <Tab>{t('survey.submission.navigation.overviewTab')}</Tab>
          <Tab>{t('survey.submission.navigation.emissionsTab')}</Tab>
          <Tab>{t('survey.submission.navigation.installationTab')}</Tab>
          <Tab>{t('survey.submission.navigation.additionalTab')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <div className="summary-header">
              <Grid>
                <Column sm={4} md={8} lg={16}>
                  <div className="details-section">
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('common.parameterTable.CNNumber')}</span>
                        <span className="value">{formState.CNNumber}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('common.parameterTable.CNName')}</span>
                        <span className="value">{formState.CNName}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step3.name')}</span>
                        <span className="value">{formState.AGC}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">
                          {t('survey.step9.reportingPeriod.reportingPeriodTitle')}
                        </span>
                        <span className="value">
                          {formState.start} - {formState.end}
                        </span>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </div>
            <h3>{t('survey.submission.prevalidation.prevalidationTitle')}</h3>
            <EmissionsComparison />
          </TabPanel>
          <TabPanel>
            <Grid>
              <Column sm={4} md={8} lg={16}>
                {/* Direct Emissions Section */}
                <div className="details-section">
                  <div className="comparison-header">
                    <h4>{t('survey.submission.prevalidation.directEmissionDetails')}</h4>
                  </div>
                  <div className="details-content">
                    {Object.entries(categories).map(([key, category]) => {
                      if (category.items.length > 0) {
                        return (
                          <div key={key} className="emissions-category">
                            <h5 className="category-title">{category.name}</h5>
                            {category.items.map((item) => (
                              <div key={item.emission_id} className="emission-item">
                                <div className="emission-name">
                                  <span className="label">
                                    {t('survey.submission.prevalidation.directEmissionSource')}
                                  </span>
                                  <span className="value">{item.emission_name}</span>
                                </div>
                                <div className="emission-details">
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.directActivityData')}
                                    </span>
                                    <span className="value">{`${item.activity} ${item.unit}`}</span>
                                  </div>
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.directFactor')}
                                    </span>
                                    <span className="value">{item.direct_multiplier}</span>
                                  </div>
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.indirectFactor')}
                                    </span>
                                    <span className="value">{item.indirect_multiplier}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>

                {/* Indirect Emissions Section */}
                <div className="details-section mt-4">
                  <div className="comparison-header"></div>
                  {/* Indirect Emissions Section */}
                  <PowerConsumptionComparison indirectEmission={formState.indirectEmission} />
                </div>
              </Column>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid>
              <Column lg={16} md={8} sm={4}>
                {/* Main Installation Info */}
                <div className="details-section installation-header">
                  <h3 className="installation-name">{formState.installationName}</h3>
                  <div className="economic-activity">
                    <span className="label">{t('survey.step1.economicActivity.labelText')}</span>
                    <span className="value">{formState.economicActivity}</span>
                  </div>
                </div>
                {formState.calculationMethod === 'calculated' && (
                  <div className="details-section production-section">
                    <div className="details-header">
                      <h4>{t('survey.submission.installationDetails.productionData')}</h4>
                    </div>
                    <div className="details-content">
                      <div className="production-grid">
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.totalProduction')}
                          </span>
                          <span className="value highlight">{formState.productionTotal} t</span>
                        </div>
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.scrapProduction')}
                          </span>
                          <span className="value highlight">{formState.productionScrap} t</span>
                        </div>
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.totalProductionCalculated')}
                          </span>
                          <span className="value highlight">{formState.productionCalc} t</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Two Column Layout for Address and Contact */}
                <div className="details-grid">
                  {/* Installation Address */}
                  <div className="details-section">
                    <div className="details-header">
                      <h4>{t('survey.step9.installationData.installationDataTitle')}</h4>
                    </div>
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('survey.step9.installationData.address')}</span>
                        <span className="value">
                          {formState.street} {formState.streetNumber}
                        </span>
                      </div>
                      {formState.POBox && (
                        <div className="details-item">
                          <span className="label">{t('survey.step1.POBox.labelText')}</span>
                          <span className="value">{formState.POBox}</span>
                        </div>
                      )}
                      {formState.additionalLine && (
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step1.additionalLine.labelText')}
                          </span>
                          <span className="value">{formState.additionalLine}</span>
                        </div>
                      )}
                      <div className="details-item">
                        <span className="label">{t('survey.step1.city.labelText')}</span>
                        <span className="value">
                          {formState.postCode} {formState.city}
                        </span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.country.labelText')}</span>
                        <span className="value">
                          {formState.country.country_name} - {formState.country.country_code}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Contact Person */}
                  <div className="details-section">
                    <div className="details-header">
                      <h4>Contact Person</h4>
                    </div>
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('survey.step1.representative.labelText')}</span>
                        <span className="value">{formState.representative || 'Not provided'}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.email.labelText')}</span>
                        <span className="value">{formState.email || 'Not provided'}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.telephone.labelText')}</span>
                        <span className="value">{formState.telephone || 'Not provided'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Column>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <div className="details-section">
                  <h4>{t('survey.step9.documents.title')}</h4>

                  {/* File Upload Categories */}
                  <div className="upload-categories space-y-6">
                    {/* Monitoring Methodology */}
                    <div className="upload-category" style={{ paddingBottom: '10px' }}>
                      <h5 className="mb-2">{t('survey.step9.documents.methodologyTitle')}</h5>
                      <FileUploader
                        accept={['.pdf', '.doc', '.docx']}
                        renderIcon={Download}
                        hasIconOnly
                        filenameStatus="edit"
                        iconDescription="Add files"
                        labelDescription={t('survey.step9.documents.methodologyDescription')}
                        labelTitle=""
                        multiple={true}
                        onChange={handleAddFile('methodology')}
                        buttonKind="primary"
                        size="md"
                        className="file-uploader"
                        buttonLabel={t('survey.step9.documents.addFilesButton')}
                      />
                    </div>

                    {/* Emission Evidence */}
                    <div className="upload-category" style={{ paddingBottom: '10px' }}>
                      <h5 className="mb-2">{t('survey.step9.documents.evidenceTitle')}</h5>
                      <FileUploader
                        accept={['.pdf', '.xlsx', '.csv']}
                        buttonKind="primary"
                        filenameStatus="edit"
                        iconDescription="Add files"
                        labelDescription={t('survey.step9.documents.evidenceDescription')}
                        multiple={true}
                        onChange={handleAddFile('evidence')}
                        size="md"
                        className="file-uploader"
                        buttonLabel={t('survey.step9.documents.addFilesButton')}
                      />
                    </div>

                    {/* Additional Documents */}
                    <div className="upload-category" style={{ paddingBottom: '10px' }}>
                      <h5 className="mb-2">{t('survey.step9.documents.additionalTitle')}</h5>
                      <FileUploader
                        accept={['.pdf', '.doc', '.docx', '.xlsx', '.csv']}
                        buttonKind="primary"
                        filenameStatus="edit"
                        iconDescription="Add files"
                        labelDescription={t('survey.step9.documents.additionalDescription')}
                        labelTitle=""
                        multiple={true}
                        onChange={handleAddFile('additional')}
                        size="md"
                        className="file-uploader"
                        buttonLabel={t('survey.step9.documents.addFilesButton')}
                      />
                    </div>
                  </div>

                  {/* Uploaded Files List */}
                  {uploadFiles.length > 0 && (
                    <div className="uploaded-files-section mt-8" style={{ paddingTop: '20px' }}>
                      <h5 className="mb-4">{t('survey.step9.documents.uploadedTitle')}</h5>
                      <StructuredListWrapper>
                        <StructuredListHead>
                          <StructuredListRow head>
                            <StructuredListCell head>
                              {t('survey.step9.documents.fileName')}
                            </StructuredListCell>
                            <StructuredListCell head>
                              {t('survey.step9.documents.category')}
                            </StructuredListCell>
                            <StructuredListCell head>
                              {t('survey.step9.documents.uploadDate')}
                            </StructuredListCell>
                            <StructuredListCell head>
                              {t('survey.step9.documents.size')}
                            </StructuredListCell>
                            <StructuredListCell head>
                              {t('survey.step9.documents.actions')}
                            </StructuredListCell>
                          </StructuredListRow>
                        </StructuredListHead>
                        <StructuredListBody>
                          {uploadFiles.map((file) => (
                            <StructuredListRow key={file.id}>
                              <StructuredListCell>{file.file.name}</StructuredListCell>
                              <StructuredListCell>
                                <Tag type="blue">{mapCategoryName(file.category)}</Tag>
                              </StructuredListCell>
                              <StructuredListCell>{file.uploadDate}</StructuredListCell>
                              <StructuredListCell>{`${(file.file.size / 1024).toFixed(
                                1,
                              )} KB`}</StructuredListCell>
                              <StructuredListCell>
                                <div className="flex gap-2">
                                  <Button
                                    kind="ghost"
                                    size="sm"
                                    renderIcon={TrashCan}
                                    iconDescription="Delete"
                                    hasIconOnly
                                    onClick={() => handleRemoveFile(file.id)}
                                  />
                                </div>
                              </StructuredListCell>
                            </StructuredListRow>
                          ))}
                        </StructuredListBody>
                      </StructuredListWrapper>
                    </div>
                  )}
                </div>
              </Column>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Form buttons */}
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" type="submit">
                {t('common.buttons.submit')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep9;
